<template>
  <financial-list
    v-model="currentPage"
    :header-data="TABLE_HEADERS"
    :events="TABLE_EVENTS"
    :data="list"
    itemRefKey='uuid'
    :disabled="loading"
    :items-per-page="itemsPerPage"
    @page-changed="loadRVPs"
    :total="total"
    :no-items-message="translations.table.empty_list"
    @row-details="() => {}"/>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';
import translations from '@/translations';

export default {
  components: {
    FinancialList,
  },
  name: 'ActiveList',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.table.name, 'name'),
      associateHeaderDataItem(this.translations.table.salesforce_id, 'salesforce_id'),
      associateHeaderDataItem(this.translations.table.title, 'title'),
      associateHeaderDataItem(this.translations.table.email, 'email'),
      associateHeaderDataItem(this.translations.table.manager_name, 'rvp_manager.name'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: this.translations.edit_details,
        icon: 'eye',
        redirectLink: uuid => `/commissions/rvps/${uuid}`,
        redirectLinkToNewPage: true,
      },
    };
  },
  data() {
    return {
      translations: translations.commissions.rvps,
      list: [],
      loading: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    await this.loadRVPs();
  },
  methods: {
    async loadRVPs() {
      this.loading = true;

      try {
        const searchFilters = {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          order_by: 'name',
          order_descending: false,
          is_active: true,
        };

        const { rvps, total } = await service.getRVPs(searchFilters);

        this.list = rvps;
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_list}: ${parseResponseError(err)}`);
      }

      this.loading = false;
    },
  },
};
</script>
