<template>
  <configuration-layout>
    <b-col class="d-flex justify-content-end m-0 p-0">
      <router-link :to="{ name: 'RVPCreate' }">
        <b-button variant="primary">{{ translations.rvps.add_new_rvp }}</b-button>
      </router-link>
    </b-col>
    <b-tabs content-class="mt-3">
      <b-tab
      v-for="(tab, index) in tabs"
      :key="tab.title"
      :title="tab.title"
      :active="selectedTab === index"
      @click="selectTab(index)">
        <b-container fluid>
          <component :is="tab.component" v-if="selectedTab === index"/>
        </b-container>
      </b-tab>
    </b-tabs>
  </configuration-layout>
</template>

<script>
import translations from '@/translations';
import ConfigurationLayout from '@/components/Commissions/ConfigurationLayout.vue';
import ActiveList from './activeList.vue';
import InactiveList from './inactiveList.vue';

export default {
  components: {
    ConfigurationLayout,
    ActiveList,
    InactiveList,
  },
  name: 'RVPs',
  data() {
    return {
      translations: translations.commissions,
      selectedTab: 0,
      tabs: [
        {
          title: translations.commissions.rvps.tab_active,
          component: 'ActiveList',
        },
        {
          title: translations.commissions.rvps.tab_inactive,
          component: 'InactiveList',
        },
      ],
    };
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
      // Clear query params when changing tabs
      const currentRoute = this.$route;
      this.$router.replace({
        path: currentRoute.path,
        query: {},
      }).catch(() => {});
    },
  },
};
</script>
